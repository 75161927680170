<template>
  <div>
    <s-processing v-model="processing" :message="message"></s-processing>
    <!--CIRCUITO DE APRBACIONES MODAL-->
    <div v-if="dialogCircuit">
      <v-dialog v-model="dialogCircuit" width="80%">
        <s-toolbar
          color="primary"
          dark
          label="Circuitos Asociados a la Operación"
          @close="dialogCircuit = false"
          close
        ></s-toolbar>
        <v-card>
          <v-container>
            <v-row>
              <v-col lg="7">
                <v-data-table
                  dense
                  item-key="AccID"
                  :items="circuits"
                  height="320"
                  :headers="[
                    { value: 'AccID', text: 'ID' },
                    { value: 'AccName', text: 'Circuito' },
                  ]"
                  @dblclick:row="createCircuit"
                  v-model="selectedCircuit"
                  @click:row="rowClickCircuit"
                />
              </v-col>
              <v-col lg="5">
                <s-crud
                  v-if="this.filterLevels.AccID != null"
                  no-toolbar
                  :config="configLevels"
                  :filter="filterLevels"
                  title="Niveles"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </div>
    <div>
      <s-toolbar
        :load="orderLocal.OrdStatus == 1"
        close
        :save="value == null || orderLocal.OrdStatus == 3"
        @save="save()"
        label="Nueva Orden de Compra"
        @close="$emit('onClose')"
        :transactionApproved="transactionApproved"
        @load="loadExternal()"
      />
      <v-card>
        <v-col>
          <v-row>
            <v-col lg="9" cols="12">
              <v-col class="pr-0 pl-0">
                <v-row>
                  <v-col class="s-col-text" cols="12" lg="5">
                    <s-supplier
                      :readonly="!isEditable"
                      label="Proveedor"
                      v-model="Supplier"
                    />
                  </v-col>
                  <v-col lg="3" class="s-col-text" cols="12"
                    ><s-contact-supplier
                      :disabled="!isEditable"
                      label="Contacto"
                      :SupID="SupID"
                      v-model="orderLocal.ConID"
                  /></v-col>
                  <v-col lg="2" class="s-col-text" cols="6"
                    ><s-select-definition
                      :disabled="!isEditable"
                      :def="1129"
                      label="Moneda"
                      v-model="orderLocal.TypeCurrency"
                  /></v-col>
                  <v-col lg="2" class="s-col-text"
                    ><s-date
                      label="Fecha"
                      v-model="dateOrder"
                      :disabled="!isEditable"
                    ></s-date
                  ></v-col>
                </v-row>
              </v-col>
              <v-row>
                <v-col>
                  <div class="mt-3 mb-3">
                    <s-toolbar
                      :elevation="1"
                      label="Detalles"
                      :add="isEditable"
                      @add="addItem()"
                      with="20"
                      :remove="isEditable"
                      @removed="removeItem()"
                    >
                      <template v-slot:options>
                        <div class="mt-1">
                          <s-select
                            :outlined="false"
                            :items="[
                              { value: 1, text: 'Articulo' },
                              { value: 3, text: 'Servicio' },
                            ]"
                            v-model="orderLocal.TypeOrder"
                          ></s-select>
                        </div>
                      </template>
                    </s-toolbar>
                  </div>
                  <v-data-table
                    dense
                    @click:row="rowClick"
                    :headers="headersSAP"
                    :items-per-page="-1"
                    v-model="selectedSAP"
                    :items="itemsSAP"
                    item-key="Line"
                    :height="300"
                    hide-default-footer
                  >
                    <template v-slot:item.OddDescription="props">
                      <div>
                        <s-text
                          v-if="orderLocal.TypeOrder == 3"
                          :maxlength="100"
                          autofocus
                          v-model="props.item.OddDescription"
                        ></s-text>
                        <s-article
                          v-if="orderLocal.TypeOrder == 1"
                          label=""
                          return-object
                          @input="inputArticle($event, props.item)"
                          v-model="props.item.ArticleObj"
                        ></s-article>
                      </div>
                    </template>
                    <template v-slot:item.TypeCostName="props">
                      <div>
                        <s-select-definition
                          v-if="isEditable"
                          abbr
                          :def="1045"
                          v-model="props.item.TypeCost"
                        />
                        <div v-else>
                          <div>{{ props.item.TypeCostName }}</div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:item.BslName="props">
                      <div>
                        <!-- $fun.hasSpecialPermission("AllLine")==true?1-->
                        <s-select-business-line
                          v-if="isEditable"
                          label=""
                          :cceID='props.item.CceID == null ? 0 :props.item.CceID'
                          v-model="props.item.BslID"
                        />
                        <div v-else>
                          <div>{{ props.item.BslName }}</div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:item.PrjName="props">
                      <div>
                        <s-select-project
                          label=""
                          v-if="isEditable"
                          v-model="props.item.PrjCode"
                        />
                        <div v-else>
                          <div>{{ props.item.PrjCode }}</div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:item.OddObservation="props">
                      <div>
                        <s-text
                          label=""
                          v-if="isEditable"
                          v-model="props.item.OddObservation"
                        />
                        <div v-else>
                          <div>{{ props.item.OddObservation }}</div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:item.CceName="props">
                      <div>
                        <s-select-area
                          v-if="isEditable"
                          centercost
                          return-object
                          @input="inputCenterCost($event, props.item)"
                          v-model="props.item.CceObject"
                        />
                        <div v-else>
                          <div>{{ props.item.CceName }}</div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:item.TaxCode="props">
                      <div>
                        <v-edit-dialog
                          v-if="isEditable"
                          large
                          @click="selectedSAP = [props.item]"
                          cancel-text="Cancelar"
                          save-text="Guardar"
                          @save="changeTax(props.item)"
                        >
                          <v-row @click="selectedSAP = [props.item]">
                            <v-col
                              ><div>{{ props.item.TaxCode }}</div></v-col
                            >
                          </v-row>
                          <template v-slot:input>
                            <div class="mt-4 title mb-4">
                              Selecciona Impuesto
                            </div>
                            <s-select-definition
                              id="DedAbbreviation"
                              return-object
                              :def="1143"
                              v-model="tax"
                            />
                          </template>
                        </v-edit-dialog>
                        <div v-else>
                          <div>{{ props.item.TaxCode }}</div>
                        </div>
                      </div>
                    </template>

                    <template v-slot:item.OddUnitPrice="props">
                      <s-text
                        style="width: 75px"
                        decimal
                        @input="changeItem(props.item)"
                        :disabled="!isEditable"
                        @paste="pastePrice($event)"
                        v-model="props.item.OddUnitPrice"
                      >
                        Precio Unitario
                      </s-text>
                    </template>
                    <template v-slot:item.OddQuantity="props">
                      <s-text
                        style="width: 80px"
                        @blur="blurQuantity($event, props.item)"
                        :disabled="!isEditable"
                        decimal
                        @paste="pasteQuantity($event)"
                        v-model="props.item.OddQuantity"
                      >
                        Cantidad Atendida
                      </s-text>
                    </template>
                    <template v-slot:item.AcoName="props">
                      <v-edit-dialog
                        large
                        v-if="isEditable"
                        @save="saveAccount(props.item)"
                        cancel-text="Cancelar"
                        save-text="Guardar"
                      >
                        <div>{{ props.item.AcoName }}</div>
                        <template v-slot:input>
                          <div class="mt-4 mb-4">
                            Concepto - Cuenta Contable
                          </div>
                          <s-select
                            item-value="ConName"
                            clearable
                            item-text="ConName"
                            label="Concepto Servicio"
                            v-model="ConName"
                            :items="itemsConcept"
                          />
                          <s-select
                            label="Cuenta Contable"
                            clearable
                            item-value="CsaID"
                            return-object
                            item-text="CsaDescription"
                            v-model="AcoID"
                            :items="itemsAccount"
                          />
                        </template>
                      </v-edit-dialog>
                      <div v-else>
                        <div>{{ props.item.AcoName }}</div>
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-col style="padding-top: 24px">
                <v-row>
                  <v-col lg="9" cols="12">
                    <v-row>
                      <v-col lg="8" class="s-col-form">
                        <s-select-definition
                          :def="1145"
                          label="Encargado de Compras"
                          :disabled="!isEditable"
                          v-model="orderLocal.OfficePurchase"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col lg="3">
                    <v-row>
                      <v-col lg="6" class="s-col-form"
                        ><v-label>SUB-TOTAL</v-label></v-col
                      >
                      <v-col lg="6" class="s-col-form"
                        ><h4 class="text-right">
                          {{
                            orderLocal.OrdSubTotal
                              ? orderLocal.OrdSubTotal.toFixed(2)
                              : "0.00"
                          }}
                        </h4>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="s-col-form"
                        ><v-label>IMPUESTO</v-label></v-col
                      >
                      <v-col class="s-col-form"
                        ><h4 class="text-right">
                          {{
                            orderLocal.OrdTaxAmount
                              ? orderLocal.OrdTaxAmount.toFixed(2)
                              : "0.00"
                          }}
                        </h4></v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col class="s-col-form">
                        <v-label>TOTAL</v-label>
                      </v-col>
                      <v-col class="s-col-form"
                        ><h4 class="text-right">
                          {{
                            orderLocal.OrdTotal
                              ? orderLocal.OrdTotal.toFixed(2)
                              : "0.00"
                          }}
                        </h4></v-col
                      >
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col lg="3" cols="12">
              <v-card elevation="0" height="520">
                <v-col class="">
                  <v-row
                    ><v-col class="s-col-text"
                      ><s-select-Campaign
                        :disabled="!isEditable"
                        label="Campaña"
                        v-model="orderLocal.CmpID"
                    /></v-col>
                  </v-row>
                  <v-row
                    ><v-col class="s-col-text"
                      ><s-text
                        label="N° Cotización"
                        :maxlength="20"
                        :disabled="!isEditable"
                        v-model="orderLocal.QuoteNumber" /></v-col
                  ></v-row>
                  <v-row>
                    <v-col class="s-col-text"
                      ><s-select-definition
                        :disabled="!isEditable"
                        label="Clase de Operación"
                        v-model="orderLocal.ClassOperationPurchase"
                        :def="1147"
                    /></v-col>
                  </v-row>
                  <v-row>
                    <v-col class="s-col-text"
                      ><s-select-definition
                        :disabled="!isEditable"
                        label="Tipo Compra"
                        :def="1146"
                        v-model="orderLocal.TypePurchase"
                    /></v-col>
                  </v-row>
                <v-row>
                  <v-col lg="12" class="s-col-form" cols="12">
                    <s-select
                      clearable label="Bienes y Servicios" 
                      :items="goodServiceList" 
                      item-text="DedDescription"
                      item-value="DedValue"
                      v-model="orderLocal.OrdGoodService" 
                    />
                  </v-col>
                </v-row>
                  <v-row>
                    <v-col class="s-col-text"
                      ><s-textarea
                        :disabled="!isEditable"
                        label="Comentarios"
                        v-model="orderLocal.OrdObservation"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col lg="12" cols="12" class="s-col-text">
                      <v-row>
                        <v-col lg="10"
                          ><v-file-input
                            :disabled="!isEditable"
                            label="Anexo 01"
                            v-model="Attach1"
                            hide-details
                        /></v-col>
                        <v-col
                          ><v-btn
                            color="success"
                            class="mt-5"
                            :disabled="false"
                            icon
                            x-small
                            @click="openFile(Attach1)"
                          >
                            <v-icon>mdi-download</v-icon>
                          </v-btn></v-col
                        >
                      </v-row>
                    </v-col>
                    <v-col lg="12" cols="12" class="s-col-text">
                      <v-row>
                        <v-col lg="10"
                          ><v-file-input
                            :disabled="!isEditable"
                            label="Anexo 02"
                            v-model="Attach2"
                            hide-details
                        /></v-col>
                        <v-col
                          ><v-btn
                            color="success"
                            class="mt-5"
                            :disabled="false"
                            icon
                            x-small
                            @click="openFile(Attach2)"
                          >
                            <v-icon>mdi-download</v-icon>
                          </v-btn></v-col
                        >
                      </v-row>
                    </v-col>
                    <v-col cols="12" lg="12" class="s-col-text">
                      <v-row>
                        <v-col lg="10"
                          ><v-file-input
                            :disabled="!isEditable"
                            label="Anexo 03"
                            v-model="Attach3"
                            hide-details
                        /></v-col>
                        <v-col
                          ><v-btn
                            color="success"
                            class="mt-5"
                            :disabled="false"
                            icon
                            x-small
                            @click="openFile(Attach3)"
                          >
                            <v-icon>mdi-download</v-icon>
                          </v-btn></v-col
                        >
                      </v-row>
                    </v-col>
                    <v-col cols="12" lg="12" class="s-col-text">
                      <v-row>
                        <v-col lg="10"
                          ><v-file-input
                            :disabled="!isEditable"
                            label="Anexo 04"
                            v-model="Attach4"
                            hide-details
                        /></v-col>
                        <v-col
                          ><v-btn
                            color="success"
                            class="mt-5"
                            :disabled="false"
                            icon
                            x-small
                            @click="openFile(Attach4)"
                          >
                            <v-icon>mdi-download</v-icon>
                          </v-btn></v-col
                        >
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </div>
  </div>
</template>

<!-- </s-crud></v-col> </v-row>
 </div>
</template> -->

<script>
import DataTableItem from "@/views/Logistics/OrderPurchase/DataTableItem.vue"; // importado para tipo copiado de Excel
import OrderAttentionPurchase from "@/views/Logistics/OrderStore/OrderPurcharseAttentionAdd.vue";
import OrderAttentionService from "@/views/Logistics/Requirement/ServiceAttentionAdd.vue";
import _sApprovalTransaction from "../../../services/Security/ApprovalTransactionService";
import SContactSupplier from "../../../components/Utils/Logistics/SContactSupplier.vue";
import SSupplier from "../../../components/Utils/Logistics/SSupplier.vue";
import _sRequirementDetail from "../../../services/Logistics/RequirementDetailService";
import _sRequirement from "../../../services/Logistics/RequirementService";
import _sGeneral from "@/services/HelperService.js";
import _sOrderPurchase from "../../../services/Logistics/OrderPurchaseService";
import SCircuitApproval from "../../../components/Utils/Security/SCircuitApproval.vue";
import _sApprovalLevel from "@/services/Security/ApprovalLevelsService";
import SSelectBusinessLine from "../../../components/Utils/SSelectBusinessLine.vue";
import SSelectDefinition from "../../../components/Utils/SSelectDefinition.vue";
import SArticle from "@/components/Utils/Logistics/SArticleSearch.vue";


export default {
  components: {
    SSupplier,
    SContactSupplier,
    SArticle,
    SCircuitApproval,
    SSelectBusinessLine,
    SSelectDefinition,
    OrderAttentionPurchase,
    OrderAttentionService,
  },
  props: { order: {}, value: null },
  data: () => ({
    goodServiceList : [],
    typeOrder: null,
    item: {
      OddDescription: "",
      ArtCode: "",
    },
    selectedTax: false,
    circuits: [],
    message: "",
    processing: false,
    selectedCircuit: [],
    filterLevels: {},
    configLevels: {
      model: {
        AccID: "int",
        AlvID: "ID",
        SecStatus: "boleam",
        PstID: "int",
        PstName: "string",
      },
      service: _sApprovalLevel,
      headers: [
        { text: "Cargo", value: "PstName" },
        { text: "Nivel", value: "TypeLevelText" },
      ],
    },
    optionApproved: false,
    dateOrder: null,
    selectedSAP: [],
    transactionApproved: null,
    tax: null,
    dialogCircuit: false,

    orderLocal: { TypeOrder: null },
    dialogAdd: false,
    itemsConcept: [],
    itemsAccount: [],
    AcoID: null,
    ConID: null,
    ConName: null,
    SupID: "",
    Supplier: null,
    itemsSAP: [],
    itemsRemove: [],
    dialogLoad: false,
    Attach1: null,
    Attach2: null,
    Attach3: null,
    Attach4: null,
    headersSAP: [
      {
        text: "Descripción",
        value: "OddDescription",
        sortable: false,
        width: 540,
      },
      { text: "Cuenta Mayor", value: "AcoName", width: 220, sortable: false },
      { text: "Prc. U.", value: "OddUnitPrice", sortable: false, width: 90 },
      { text: "Impuesto", value: "TaxCode", sortable: false, width: 60 },
      { text: "(%)Imp", value: "OddTaxPercentage", sortable: false, width: 70 },
      {
        text: "SubTotal",
        value: "OddAmount",
        sortable: false,
        width: 70,
        align: "right",
      },
      { text: "Centro Costo", value: "CceName", sortable: false, width: 300 },
      {
        text: "Linea de Negocio",
        value: "BslName",
        sortable: false,
        width: 300,
      },
      {
        text: "Tipo Costo",
        value: "TypeCostName",
        sortable: false,
        width: 100,
      },
      { text: "Proyecto", value: "PrjName", sortable: false, width: 300 },
      {
        text: "Comentario",
        value: "OddObservation",
        sortable: false,
        width: 400,
      },
    ],
  }),
  computed: {
    isEditable() {
      return (
        (this.orderLocal.OrdStatus == 3 &&
          this.$fun.getUserID() == this.orderLocal.UsrCreateID) ||
        this.orderLocal.OrdID == null
      );
    },
  },
  methods: {
    getGoodServiceList()
    {
      _sRequirement
      .goodservicelist(this.$fun.getUserID())
      .then(resp => {
        if(resp.status == 200)
        {
          this.goodServiceList = resp.data;
        }
      })
    },

    inputArticle(article, item) {
      // console.log("article:",article);
      item.UnitMeasurement = article.UnitOfMeasuarement;
      item.ArtCode = article.ArtCode;
      item.OddDescription = article.ArtName;
    },
    inputCenterCost(center, item) {
      if (center) item.CceID = center.GenID;
      else item.CceID = 0;
    },
    addItem() {
      this.item.Line = this.itemsSAP.length + 1;
      this.item.OddStatus = 1;  // campo en observación
      this.itemsSAP.push({ ...this.item });
      // console.log('agregamos items', this.itemsSAP); 
    },
    openFile(url) {
      _sGeneral.download(url.name, this.$fun.getUserID()).then((r) => {
        if(r.status === 200){
          this.$fun.downloadHttp(r);
        }       
      });
    /*  let showM = 0;
      _sGeneral.download(url.name, this.$fun.getUserID()).then((r) => {
        if(r.status === 200){
          this.$fun.downloadHttp(r);
          showM = 1;
        }       
      });
      .catch(error => {
        console.log("error samir",error)
      });

      if(showM == 0){ 
        alert("NO se encontro el Archivo","warning")
        console.log("showM", showM)
        };
*/
    },

    selectedAttention(items) {
      // console.log("selectedAttention:",items);
      for (let i = 0; i < items.length; i++) {
        items[i].OddDescription = items[i].DerDescription;
        items[i].OddQuantity = items[i].DerQuantity;
        items[i].Line = i + this.itemsSAP.length + 1;
      }
      this.itemsSAP.push(...items);
      this.dialogAdd = false;
    },

    removeItem() {
      if (this.selectedSAP.length == 1) {
        if (this.itemsSAP.length <= 1) {
          this.$fun.alert(
            "No se permite eliminar el ultimo item de la orden",
            "warning"
          );
          return;
        }
        this.itemsSAP = this.itemsSAP.filter(
          (x) => x.Line != this.selectedSAP[0].Line
        );
        this.selectedSAP[0].OddStatus = 0;

        if (this.selectedSAP[0].OddID > 0)
          this.itemsRemove.push(this.selectedSAP[0]);
        //this.selectedSAP[0]=this.itemsSAP[0];
        this.changeTotal();
      }
    },

    blurQuantity(event, item) {
      if (item.DerQuantity < item.OddQuantity || item.OddQuantity <= 0) {
        this.$fun.alert("Cantidad Incorrecta", "warning");
        item.OddQuantity = item.DerQuantity;
        this.changeItem(item);
        //this.itemsSAP.splice(this.itemsSAP.indexOf(item),1,item);
      }
      this.changeItem(item);
    },

    pasteQuantity(event) {

      event.preventDefault();
      let quantitys = [];
      let i = 0;
      let isValid = true;
      event.clipboardData
        .getData("text/plain")
        .split("\n")
        .forEach((element) => {
          if (element.trim() != "") {
            quantitys[i] = parseFloat(element.trim());
            if (Number.isNaN(quantitys[i])) {
              this.$fun.alert("Datos incorrectos", "warning");
              isValid = false;
            }
            i++;
          }
        });

      if (isValid == true)
        for (i = 0; i < this.itemsSAP.length && i < quantitys.length; i++) {
          this.itemsSAP[i].OddQuantity = quantitys[i];
          this.changeItem(this.itemsSAP[i]);
        }
    },
    pastePrice(event) {
      event.preventDefault();
      let quantitys = [];
      let i = 0;
      let isValid = true;
      event.clipboardData
        .getData("text/plain")
        .split("\n")
        .forEach((element) => {
          if (element.trim() != "") {
            quantitys[i] = parseFloat(element.trim());
            if (Number.isNaN(quantitys[i])) {
              this.$fun.alert("Datos incorrectos", "warning");
              isValid = false;
            }
            i++;
          }
        });
      if (isValid == true)
        for (i = 0; i < this.itemsSAP.length && i < quantitys.length; i++) {
          this.itemsSAP[i].OddUnitPrice = quantitys[i];
          this.changeItem(this.itemsSAP[i]);
        }
    },
    changeTotal() {
      this.orderLocal.OrdSubTotal = 0;
      this.orderLocal.OrdTaxAmount = 0;
      this.itemsSAP.forEach((element) => {
         
        let unitPrice = element.OddUnitPrice == null ? 0 : element.OddUnitPrice;
        this.orderLocal.OrdSubTotal =
          parseFloat(this.orderLocal.OrdSubTotal) +
          parseFloat(unitPrice) *
            (element.OddQuantity == null ? 1 : parseFloat(element.OddQuantity));
        this.orderLocal.OrdTaxAmount =
          parseFloat(this.orderLocal.OrdTaxAmount) +
          unitPrice *
            parseFloat(element.OddQuantity) *
            (element.OddTaxPercentage == null
              ? 0
              : parseFloat(element.OddTaxPercentage) / 100);
      });

      this.orderLocal.OrdTotal =
        this.orderLocal.OrdSubTotal + this.orderLocal.OrdTaxAmount;
    },

    loadExternal() {
      this.$fun.alert("Seguro de Cargar Orden?", "question").then((r) => {
        if (r.value) {
          this.processing = true;
          _sOrderPurchase
            .loadExternal(
              this.orderLocal,
              this.$fun.getUserID(),
              this.$fun.getUserID()
            )
            .then(
              (r) => {
                this.processing = false;
                this.$fun.alert("Cargado Correctamente", "success");
                this.$emit("onClose");
              },
              (e) => {
                this.message = e.response.data.Message;
                this.processing = true;
              }
            );
        }
      });
    },
    rowClickCircuit: function (item, row) {
      this.selectedCircuit = [item];
      this.filterLevels = { AccID: item.AccID };
    },
    createCircuit(item) {
      this.$fun
        .alert("Guardar con " + this.selectedCircuit[0].AccName, "question")
        .then((r) => {
          if (r.value) {
            this.orderLocal.UsrUpdateID = this.selectedCircuit[0].AccID;
            this.dialogCircuit = false;
            this.orderLocal.Detail = this.itemsSAP;
            _sOrderPurchase
              .save(
                this.orderLocal,
                this.$fun.getUserID(),
                this.selectedCircuit[0].AccID
              )
              .then((r) => {
                this.$emit("onClose");
                this.$fun.alert("Agregado Correctamente", "success");
                this.$emit("close");
              });
          }
        });
    },
    changeItem(item) {
      // console.log("changeItem:", item);
      this.orderLocal.OrdSubTotal = 0;
      this.orderLocal.OrdTaxAmount = 0;
      let edit = Object.assign(
        {},
        this.itemsSAP.find((x) => x.Line == item.Line)
      );
      let indexEdit = this.itemsSAP.indexOf(item);
      if (edit.CceObject) {
        let cce = edit.CceID;
        edit.CceID = edit.CceObject.GenID;
        edit.CceName = edit.CceObject.GenDescription;
        if (edit.CceObject.GenID != cce) {
          edit.BslID = null;
          edit.BslName = "";
        } else {
          if (edit.BslObject) {
            edit.BslID = edit.BslObject.GenID;
            edit.BslName = edit.BslObject.GenDescription;
          }
        }
      }
      if (edit.TypeCostObject) {
        edit.TypeCost = edit.TypeCostObject.DedValue;
        edit.TypeCostName = edit.TypeCostObject.DedDescription;
      }

      edit.OddQuantity =
        edit.OddQuantity == null ? 1 : parseFloat(edit.OddQuantity);

      edit.OddTaxAmount = parseFloat(
        ((edit.OddTaxPercentage == null ? 0 : edit.OddTaxPercentage) / 100) *
        edit.OddUnitPrice *
        edit.OddQuantity).toFixed(6);
      edit.OddAmount = parseFloat(
        parseFloat(edit.OddUnitPrice * edit.OddQuantity) +
        parseFloat(edit.OddTaxAmount == null ? 0 : edit.OddTaxAmount)).toFixed(6);
      // console.log("edit:", edit);
      this.itemsSAP.splice(indexEdit, 1, edit);

      let sOrdTotal = 0;
      let sOrdTaxAmount = 0;
      this.itemsSAP.forEach((element) => {
        let unitPrice =
          element.OddUnitPrice == null || element.OddUnitPrice == ""
            ? 0
            : element.OddUnitPrice;

          sOrdTotal = parseFloat(sOrdTotal) + (element.OddAmount == null ? 0 : parseFloat(element.OddAmount));
          sOrdTaxAmount = parseFloat(sOrdTaxAmount) + (element.OddTaxAmount == null ? 0 : parseFloat(element.OddTaxAmount)); 
      });
      this.orderLocal.OrdSubTotal = sOrdTotal - sOrdTaxAmount;
      this.orderLocal.OrdTaxAmount = sOrdTaxAmount ;

      this.orderLocal.OrdTotal = sOrdTotal;

    },
    rowClick: function (item, row) {
      this.selectedSAP = [item];
    },

    saveAccount(item) {
      // console.log("Item ED:", item, this.AcoID);
      if (this.AcoID != null) {
        let edit = this.itemsSAP.find((x) => x.Line == item.Line);
        let indexEdit = this.itemsSAP.indexOf(item);
        edit.AcoName = this.AcoID.CsaDescription;
        edit.OddAccount = this.AcoID.CsaAccount;
        edit.OddAccountName = this.AcoID.CsaAccountName;
        this.itemsSAP.splice(indexEdit, 1, edit);
      }
    },
    changeTax(item) {
      if (this.tax != null)
        if (this.selectedTax) {
          item.OddTaxPercentage = parseFloat(this.tax.DedHelper).toFixed(2);
          item.TaxCode = this.tax.DedAbbreviation;
          item.OddTaxAmount =
            ((item.OddUnitPrice == null
              ? 0
              : item.OddUnitPrice * item.OddQuantity) *
              item.OddTaxPercentage) /
            100;
          this.changeItem(item);
          this.tax = null;
        } else {
          this.itemsSAP.forEach((e) => {
            e.OddTaxPercentage = parseFloat(this.tax.DedHelper).toFixed(2);
            e.TaxCode = this.tax.DedAbbreviation;
            e.OddTaxAmount =
              ((e.OddUnitPrice == null ? 0 : e.OddUnitPrice * e.OddQuantity) *
                e.OddTaxPercentage) /
              100;
            this.changeItem(e);
          });
          this.selectedTax = true;
        }
    },
    dblClick(item) {},
    save() {

      // console.log(this.Supplier);
      // console.log("this.itemsSAP:",this.itemsSAP);
      
      if(this.orderLocal.OrdGoodService == undefined || this.orderLocal.OrdGoodService == 0)
      {
        this.$fun.alert("Debe agregar el tipo de bien y servicio", "warning");
        return;
      }
      
      if (this.Supplier == null) {
        this.$fun.alert("Falta proveedor", "warning");
        return;
      }
      if (this.Attach1 == null) {
        this.$fun.alert("Falta adjuntar archivo", "warning");
        return;
      }
      if (this.itemsSAP.length < 1) {
        this.$fun.alert("Falta Items", "warning");
        return;
      }
      for (let i = 0; i < this.itemsSAP.length; i++) {
        
        if (this.itemsSAP[i].OddDescription) {
          if (this.itemsSAP[i].OddDescription.trim().length < 1) {
            this.$fun.alert("Falta Descripción", "warning");
            return;
          }
        } else {
          this.$fun.alert("Falta Descripción", "warning");
          return;
        }
        if (
          this.itemsSAP[i].OddUnitPrice == null ||
          this.itemsSAP[i].OddUnitPrice == 0
        ) {
          this.$fun.alert(
            "Precio inválido, " + this.itemsSAP[i].OddDescription,
            "warning"
          );
          return;
        }
        if (this.itemsSAP[i].CceID == null) {
          this.$fun.alert(
            "Falta Centro de Costo en, " + this.itemsSAP[i].OddDescription,
            "warning"
          );
          return;
        }
        if (this.itemsSAP[i].BslID == null) {
          this.$fun.alert(
            "Falta Linea de Negocio en, " + this.itemsSAP[i].OddDescription,
            "warning"
          );
          return;
        }
        if (this.itemsSAP[i].TypeCost == null) {
          this.$fun.alert(
            "Falta Tipo de Costo, " + this.itemsSAP[i].OddDescription,
            "warning"
          );
          return;
        }
        if (this.itemsSAP[i].TaxCode == null) {
          this.$fun.alert(
            "Agrege Impuesto a, " + this.itemsSAP[i].OddDescription,
            "warning"
          );
          return;
        }
        if (
          this.itemsSAP[i].OddUnitPrice == null ||
          this.itemsSAP[i].OddUnitPrice == 0
        ) {
          this.$fun.alert(
            "Precio inválido, " + this.itemsSAP[i].OddDescription,
            "warning"
          );
          return;
        }
        if (this.typeOrder == 3) {
          if (this.itemsSAP[i].OddAccount == null) {
            this.$fun.alert(
              "Agrege Concepto a, " + this.itemsSAP[i].OddDescription,
              "warning"
            );
            return;
          }
          this.itemsSAP[i].OddQuantity = 1;
        }
        this.itemsSAP[i].TypeCurrency = this.orderLocal.TypeCurrency;
        // console.log("this.itemsSAP[i]",this.itemsSAP[i]);
        this.itemsSAP[i].SisCode = this.itemsSAP[i].SisID;
        this.itemsSAP[i].SitCode = this.itemsSAP[i].SitID;
      }
      this.orderLocal.Detail = this.itemsSAP;

      this.orderLocal.SupCode = this.Supplier.SupID;
      this.orderLocal.SupName = this.Supplier.SupName;
      this.orderLocal.OrdAttach1 =
        this.Attach1 != null ? this.Attach1.name : "";
      this.orderLocal.OrdAttach2 =
        this.Attach2 != null ? this.Attach2.name : "";
      this.orderLocal.OrdAttach3 =
        this.Attach3 != null ? this.Attach3.name : "";
      this.orderLocal.OrdAttach4 =
        this.Attach4 != null ? this.Attach4.name : "";
      this.orderLocal.CardName = this.Supplier.SupName;
      this.orderLocal.OrdDate = this.dateOrder;
      this.orderLocal.UsrUpdateID = this.$fun.getUserID();

      // console.log("orderLocal", this.orderLocal);

      this.$fun.alert("Seguro de guardar?", "question").then((r) => {
        if (r.value) {
          this.orderLocal.Detail.push(...this.itemsRemove);

          let files = [];
          if (this.Attach1) files.push(this.Attach1);
          if (this.Attach2) files.push(this.Attach2);
          if (this.Attach3) files.push(this.Attach3);
          if (this.Attach4) files.push(this.Attach4);
          var formData = new FormData();
          let i = 0;
          files.forEach((element) => {
            formData.append(i.toString(), element);
            i++;
          });
          _sGeneral.uploadfile("123", this.$fun.getUserID(), formData, 3)
          .then((r) => {
            // console.log("respuesta de upload", r);
            if(r.status === 200){
              _sOrderPurchase
              .save(this.orderLocal, this.$fun.getUserID(), 0)
              .then((r) => {
                if (r.data.length > 1) {
                  // console.log("ingreso al if");
                  this.circuits = r.data;
                  this.dialogCircuit = true;
                } else {
                  // console.log("ingreso al else");
                  this.$fun.alert("Guardado Correctamente", "success");
                  this.$emit("onClose");
                }
              });
            } else {
              this.$fun.alert("Error al subir archivos, intentelo nuevamente", "warning");
            }
          });
        }
      });

      // this.$fun.alert("Seguro de guardar?", "question").then((r) => {
      //   if (r.value) {
      //     this.orderLocal.Detail.push(...this.itemsRemove);

      //     _sOrderPurchase
      //       .save(this.orderLocal, this.$fun.getUserID(), 0)
      //       .then((r) => {
      //         if (r.data.length > 1) {
      //           console.log("ingreso al if");
      //           this.circuits = r.data;
      //           this.dialogCircuit = true;
      //         } else {
      //           console.log("ingreso al else");
      //           let files = [];
      //           if (this.Attach1) files.push(this.Attach1);
      //           if (this.Attach2) files.push(this.Attach2);
      //           if (this.Attach3) files.push(this.Attach3);
      //           if (this.Attach4) files.push(this.Attach4);
      //           var formData = new FormData();
      //           let i = 0;
      //           files.forEach((element) => {
      //             formData.append(i.toString(), element);
      //             i++;
      //           });
      //           _sGeneral.uploadfile("123", this.$fun.getUserID(), formData, 3)
      //           .then((r) => {
      //             console.log("respuesta de upload", r);
      //           });
                
      //           this.$fun.alert("Guardado Correctamente", "success");
      //           this.$emit("onClose");
      //         }
      //       });
      //   }
      // });
    },
  },

  created() {
    this.getGoodServiceList();
    this.orderLocal.TypeOrder = 3;
    this.orderLocal.TypeOrder = 1;

    this.orderLocal.ManagerPurchase = this.$fun.getUserSAPInfo().UssWorkerID;
    _sRequirement.ConceptService(this.$fun.getUserID()).then((r) => {
      this.itemsConcept = r.data;
      if (this.itemsConcept.length > 0)
        this.ConName = this.itemsConcept[0].ConName;
    });
  },
  watch: {
    "orderLocal.TypeOrder"() {
      this.itemsSAP = [];
      this.typeOrder = this.orderLocal.TypeOrder;
      if (this.orderLocal.TypeOrder == 3) {
        //this.headersSAP=[];
        this.headersSAP.splice(1, 3, {
          text: "Cuenta Mayor",
          value: "AcoName",
          width: 220,
          sortable: false,
        });
      } else if (this.orderLocal.TypeOrder == 1) {
        this.headersSAP.splice(
          1,
          1,
          { text: "N° Art.", value: "ArtCode", sortable: false, width: 50 },
          { text: "Und", value: "UnitMeasurement", sortable: false },
          { text: "Atendido", value: "OddQuantity", sortable: false }
        );
      }
    },
    ConName() {
      // console.log("Datawert1y2u3", this.ConName);
      _sRequirement
        .AccountConceptService(this.ConName, this.$fun.getUserID())
        .then((ar) => {
          this.itemsAccount = ar.data;
          // console.log("itemsAccount ED:", this.itemsAccount, this.AcoID);
          if (this.itemsAccount.length > 0) this.AcoID = this.itemsAccount[0];
        });
    },
    Supplier() {
      if (this.Supplier != null)
        if (this.Supplier.SupID != null) this.SupID = this.Supplier.SupID;
    },
  },
};
</script>