<template>
  <div>
    <v-dialog v-model="showAddNewOrder" v-if="showAddNewOrder" persistent>
      <order-purchase-new
        @onClose="
          showAddNewOrder = false;
          $refs.crud.refresh()"
        v-if="showAddNewOrder"
      ></order-purchase-new>
    </v-dialog>

    <div v-if="dialogEdit">
      <v-dialog v-model="dialogEdit" persistent width="100%">
        <v-card>
          <order-purchase-edit
            @onClose="
              dialogEdit = false;
              $refs.crud.refresh();
            "
            :value="parentID"
            :typeOrder="1"
            :order="{}"
            :viewLoad="true"
          />
        </v-card>
      </v-dialog>
    </div>
    <v-row>
      <v-col md="12" lg="12" sm="12" class="pb-1">
        <s-crud
          excel
          add
          @add="showAddNewOrder = true"
          sortable
          :view="item.OrdStatus != 3"
          searchInput
          :remove="item.OrdStatus != 4 && item.OrdStatus != 6 && item.OrdStatus != 5"
          add-no-function
          :pdf="item.OrdStatus == 5"
          @pdf="pdf($event)"
          delete-no-function
          @delete="deleteEvent($event)"
          :config="config"
          @save="save($event)"
          ref="crud"
          @edit="dialogEdit = true"
          :filter="filter"
          :edit="item.OrdStatus == 3"
          title="Orden de Compra"
          @rowSelected="selectRows($event)"
          
          sync
          @sync="syncOrder()">
          <!-- btnsearch -->
          <template v-slot:OrdTotal="{ row }">
            <v-container>
              <v-row justify="center">
                <v-col>
                  {{ row.OrdTotal >= 1000 ? row.OrdTotal.toLocaleString("es-MX") : row.OrdTotal.toFixed(2) }}
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:filter>
            <v-container>
              <v-row justify="center">
                <v-col v-if="$fun.isAdmin()" lg="3" class="pt-0 pb-0" >
                  <s-select 
                    label="Usuario"
                    placeholder="Digite Usuario" 
                    autocomplete
                    clearable
                    item-text="NtpFullName"
                    v-model="filter.UsrID"
                    item-value="UsrID"
                    :items="itemsUsers"
                    @input="selectedUsrID($event)"
                  ></s-select>
                </v-col>
               <v-col lg="3"  class="pt-0 pb-0" v-if="$fun.isLevelArea()">
                  <s-select-area-allow 
                    label="Area"
                    v-model="filter.AreID" 
                  ></s-select-area-allow>
                </v-col>
                <v-col lg="3" cols="12" class="pt-0 pb-0">
                  <s-provider v-model="filter.SupCode" />
                </v-col> 
                <v-col lg="2" cols="12" class="pt-0 pb-0">
                  <s-date label="Fecha Inicio" :clearable="clearableFilter" v-model="filter.BeginDate" />
                </v-col>
                <v-col lg="2" cols="12" class="pt-0 pb-0"> 
                  <s-date label="Fecha Fin" :clearable="clearableFilter" v-model="filter.EndDate"/>
                </v-col>
                <v-col lg="2" cols="12" class="pt-0"> 
                  <s-select-definition clearable label="Estados" :def="1151" v-model="filter.OrdStatus"/>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:OrdStatusName="{ row }">
            <v-chip x-small dense :color="row.OrdStatus == 4 ? 'primary' : row.OrdStatus == 3 ? 'error' : row.OrdStatus == 6 ? 'error' : ''">{{
              row.OrdStatusName
            }}</v-chip>
          </template>
        </s-crud>
      </v-col>
      <v-col class="mt-0 pt-0" lg="8" cols="12" v-if="item">
        <ApprovalLevelsDocument :DocEntry="item.OrdID" :TypeObject="2" />
      </v-col>
    </v-row>
<!-- 
    <v-row>
       {{ this.filter  }} 
      </v-row> -->
  </div>
</template>

<script>
import _sPermit from "@/services/Logistics/PermitService";
import _sOrderPurchase from "@/services/Logistics/OrderPurchaseService";
import OrderPurchaseEdit from "@/views/Logistics/OrderPurchase/OrderPurchaseEdit";
import OrderPurchaseNew from "@/views/Logistics/OrderPurchase/OrderPurchaseNew";
import ApprovalLevelsDocument from "@/views/Security/Circuit/Levels/ApprovalLevelsDocument";
export default {
  components: { OrderPurchaseEdit, ApprovalLevelsDocument, OrderPurchaseNew },

  data: () => ({ 
    clearableFilter: true,
    //NEW ORDER
    arePermiss: [],
    areID: null,
    itemsUsers: [],
    showAddNewOrder: false,
    //
    item: {},
    dialogEdit: false,
    disabledRate: false,
    filter: { 
      TypeOrder: 0, 
      BeginDate: null, 
      EndDate: null ,
      UsrID: null,
      SupCode : null,
      AreID : null,
      OrdStatus: null,
    },
    config: {
      model: {
        OrdID: "ID",
        OrdDate: "date",
        OrdStatusName: "",
        OrdStatus: "",
        SecUpdate: "date",
        OrdTotal: "",
      },
      service: _sOrderPurchase,
      headers: [
        { text: "ID", value: "OrdID", sortable: false },
        { text: "Documento", value: "OrdDocumentNumber" },
        { text: "Fecha", value: "OrdDate" },

        { text: "Orden", value: "OrdDocNum" },
        // { text: "Area", value: "AreName", sortable: false },
        { text: "Total", value: "OrdTotal" },
        { text: "Proveedor", value: "SupName" },
        { text: "Usuario", value: "NtpFullName" },
        { text: "Fecha Act.", value: "SecUpdate" },
        {
          text: "Estado",
          value: "OrdStatusName",
          sortable: false,
          align: "center",
        },
      ],
    },
  }),
  watch:{
    filter(){
      
    }
  },
  created(){
    this.filter.UsrID = parseInt(this.$fun.getUserID());
    var objPers = {};
    //AreasAllow this.$fun.isAdmin() ? null : 
    // console.log("this.$fun.getSecurity()", this.$fun.getSecurity());
    if (this.$fun.isAdmin()) {
        
        objPers.AreID = this.areID; 
        
        _sPermit.users(objPers, this.$fun.getUserID()).then((r) => {
          this.itemsUsers = r.data;  
          this.filter.UsrID = parseInt(this.$fun.getUserID()); 
        }); 
        
    } 

    if (this.$fun.isLevelArea()) {
      this.arePermiss = this.$fun.getSecurity().AreasAllow;  
    
      this.filter.AreID = this.arePermiss[0].AreID;
      this.clearableFilter = false;  
      this.filter.UsrID = null;
    } 
    
     
  },
  mounted() {
    
  },

  methods: { 
    syncOrder(){
      if (this.item.OrdStatus == 5) {
        console.log("syncOrder")
        _sOrderPurchase.setStatus(this.item, this.$fun.getUserID())
          .then((r)=>{
            this.$refs.crud.refresh();
            this.$fun.alert("Sincronizado Correctamente", "success");
          });
      }
    },
    selectedUsrID(item){
       
    },
    pdf(item) {
      console.log("verificando archivo de impresion");
      console.log(item);

      _sOrderPurchase.PDF(item, this.$fun.getUserID()).then((r) => {
        this.$fun.downloadFile(r.data, this.$const.TypeFile.PDF, "Orden_compra");
      });
    },

    deleteEvent(item) {
      this.$fun.alert("Seguro de Anular Orden de Compra?", "question").then((r) => {
        if (r.value) {
          item.OrdStatus = 6;
          _sOrderPurchase.setStatus(item, this.$fun.getUserID()).then((r) => {
            this.$refs.crud.refresh();
            this.$fun.alert("Anulado Correctamente", "success");
          });
        }
      });
    },

    selectRows(items) {
      if (items.length > 0) {
        // this.filter.OrdID=items[0].OrdID
        this.parentID = items[0].OrdID;
        this.item = items[0];
        // console.log("🚀 ~ file: Orders.vue ~ line 196 ~ selectRows ~ this.item", this.item);
      } else {
        this.parentID = null
        this.item = {};
      };
    },
  },
};
</script>
